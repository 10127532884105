/**
 * @file This file is in an "internal" directory to prevent it from being
 * used outside of the `GlobalProvider` component. This is to ensure it
 * doesn't get confused with some more typical hook for getting the `CartVM`
 * from the global context.
 */

import CartVM from '@/react/view-models/CartVM';
import { useCallback, useRef, useState, useTransition } from 'react';
import type { RevalidateCartFn } from '../RevalidateCartContext';

/**
 * A utility hook for managing the creation of a new {@link CartVM} and
 * synchronizing it with server-side state on the client.
 * @returns A 2-tuple of a {@link CartVM} instance and a concurrent mode
 * compatible function to revalidate it with the server.
 */
export function useCartState(): [CartVM, RevalidateCartFn] {
  const [cart, setCart] = useState<CartVM>(() => new CartVM());

  const [, startTransition] = useTransition();
  const nextCartRef = useRef<CartVM | null>(null);
  const revalidateCart = useCallback(() => {
    const newCart = new CartVM();
    nextCartRef.current = newCart;
    // We cannot simply call `refresh()` on the existing CartVM instance
    // because MobX state updates cannot be marked as transitions.
    startTransition(async () => {
      await newCart.refresh();
      // Prevent race condition if `revalidateCart()` was called multiple times
      if (nextCartRef.current === newCart) {
        // state updates after an async call must be re-wrapped
        // in a transition to be part of the same transition
        startTransition(() => setCart(newCart));
        nextCartRef.current = null; // clean up the reference
      }
    });
  }, []);

  return [cart, revalidateCart];
}
